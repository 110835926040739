import React from "react"
import Layout from "../components/layout.js"
import Meta from "../components/meta.js"
export default function Discord() {
  return (
    <Layout>
      <Meta />
      <meta http-equiv="refresh" content="0;url=https://docs.google.com/forms/d/e/1FAIpQLSc6c4zwDZTskonae4hhhmwH7C9FxLRvRGpEKlre5g4ZE4LYng/viewform" />
    </Layout>
  )
}
